import { Box } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { MarkdownComponent } from "../../components/markdownComponent";
import { BlockSelector } from "../../components/blockSelector";

interface BlockProps {
  id: string;
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'text' | 'table' | 'quote' | 'toggleList' | 'divider' | 'text';
  text: string;
}

export const NewPostPage: FC = () => {
  const [blocks, setBlocks] = useState<BlockProps[]>([]);
  const [currentFocus, setCurrentFocus] = useState(0);

  const handleAddNewBlock = () => {
    const newEmptyBlock: BlockProps = {
      id: Date.now().toString(),
      type: 'text',
      text: '',
    };

    const temp: BlockProps[] = [...blocks];
    temp.splice(currentFocus + 1, 0, newEmptyBlock);
    setBlocks(temp);
  }

  const handleDeleteBlock = () => {
    console.log("handleDeleteBlock");
    if (currentFocus === 0) return;
    const temp: BlockProps[] = [...blocks];
    temp.splice(currentFocus, 1);
    setBlocks(temp);
  }

  useEffect(() => {
    console.log(currentFocus);
  }, [currentFocus])

  useEffect(() => {
    console.dir(blocks, { depth: null })
    if (blocks.length === 0) handleAddNewBlock();

  }, []);

  useEffect(() => {
    console.dir(blocks, { depth: null })
  }, [blocks])

  return (
    <Box sx={{
      height: '100%', // 調整為足夠高度，可以是具體的像素值或百分比
      display: 'flex',
      flexDirection: 'column',
      bgcolor: '#092290'
    }}>
      {/* {block.map((item) => {
        return <MarkdownComponent markdown={''} />
      })} */}

      {blocks.length !== 0 ? <BlockSelector key={blocks[0].id} titleBlock isLock={false} newNextBlock={handleAddNewBlock}
        callBackIndex={() => {
          setCurrentFocus(0);
        }}
        deleteBlock={handleDeleteBlock} /> : null}

      {blocks.map((item, index) => {
        if (index === 0) return null;
        return <BlockSelector key={item.id} isLock={false} newNextBlock={handleAddNewBlock}
          callBackIndex={() => {
            setCurrentFocus(index);
          }}
          deleteBlock={handleDeleteBlock} />
      })}
    </Box>
  );
}

export default NewPostPage;