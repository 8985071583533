import { useTransition, animated, AnimatedProps, useSpringRef } from '@react-spring/web';
import { FC, useState, CSSProperties, useEffect } from "react";
import { PageBox } from './styles';



interface SlickPageProps {
  ReactElement: React.ReactElement[];
}

export const SlickPage: FC<SlickPageProps> = (props) => {
  // const [pages, setPages] = useState<((props: AnimatedProps<{ style: CSSProperties }>) => React.ReactElement)[]>();
  const [index, set] = useState(0)
  const onClick = () => set(state => (state + 1) % props.ReactElement.length)
  const transRef = useSpringRef()
  const transitions = useTransition(index, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: 'translate3d(50%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  });

  const pages = props.ReactElement.map((item, index) => {
    return ({ style }: AnimatedProps<{ style: CSSProperties }>) => (
      <animated.div
        key={index}
        style={{
          ...style,
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        {item}
      </animated.div>
    );
  });

  useEffect(() => {
    transRef.start()
  }, [index]);

  return (
    <PageBox onClick={onClick}>
      {transitions((style, i) => {
        const Page = pages[i]
        return <Page style={style} />
      })}
    </PageBox>
  );
}

export default SlickPage;

