import { FC } from "react";
import { BtnText, BtnWrap, OAuthLogo } from "./styles";

interface GithubOAuthProps {
  onClick?: () => void;
}

export const GithubOAuth: FC<GithubOAuthProps> = (prop) => {
  return <BtnWrap onClick={prop.onClick}>
    <OAuthLogo src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/GitHub_Invertocat_Logo.svg/640px-GitHub_Invertocat_Logo.svg.png" />
    <BtnText>Continue with Github</BtnText>
  </BtnWrap>
}

export default GithubOAuth;