import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Collapse, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import { ListProps, ItemCollapse, ListItemLinkProps } from "./types";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { MenuBox } from "./styles";

type Anchor = 'top' | 'left' | 'bottom' | 'right';
interface NodeState {
  uid: string;
  open: boolean;
}

type DrawerListProps = {
  anchor?: Anchor;
  drawerListState: boolean;
  setDrawerListState: Dispatch<SetStateAction<boolean>>;
  inputList: ListProps[];
};

function isItemMatchCollapse(obj: any): obj is ItemCollapse {
  return 'title' in obj;
}



export const DrawerList: FC<DrawerListProps> = ({ anchor = 'left', drawerListState: isDrawerListOpen, setDrawerListState, inputList }) => {
  const [open, setOpen] = useState(false);
  const [nodeState, setNodeState] = useState<NodeState[]>([]);

  const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(isOpen);
  };

  function getParserFromList(item: ListItemLinkProps, isNeedTab: boolean) {
    if (item.primary === undefined && item.to !== undefined) {
      return;
    } else if (item.to === undefined && item.primary !== undefined && item.icon === undefined) {
      // here is a title without link
      <ListItem key={item.uid} disablePadding>
        <ListItemText primary={item.primary} />
      </ListItem>
    } else if (item.primary !== undefined && item.to !== undefined) {
      // here is a link
      return (
        <ListItem key={item.uid} disablePadding >
          <ListItemButton component={Link} to={item.to}
            onClick={toggleDrawer(false)}>
            {isNeedTab ? <ListItemIcon></ListItemIcon> : null}
            {item.icon !== undefined ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
            <ListItemText primary={item.primary} />
          </ListItemButton>
        </ListItem>
      );
    } else if (item.icon !== undefined) {
      // here is object (logo, divider)
      return (
        item.icon
      );
    }

    return <></>;
  }

  const handleClick = (uid: string) => {
    setNodeState(prevNodeState => {
      const prevalue = prevNodeState.length > 0 ? prevNodeState[prevNodeState.length - 1].open : false;
      const newState = [...prevNodeState];
      const index = newState.findIndex(node => node.uid === uid);
      if (index >= 0) {
        newState[index] = { uid, open: !prevalue };
      } else {
        newState.push({ uid, open: !prevalue });
      }
      return newState;
    });
  };

  function getListFilter(item: ListProps) {
    if (isItemMatchCollapse(item)) {
      // here is a nest node
      const index = nodeState.findIndex(node => node.uid === item.uid);
      const isOpen = nodeState.length > 0 ? nodeState[index].open : false;
      const nodeUID = item.uid;
      const myList = item.subList;
      return (
        <List key={`node_${item.uid}`}>
          <ListItem key={item.uid} disablePadding >
            <ListItemButton onClick={() => { handleClick(nodeUID) }}>
            {item.icon !== undefined ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
            <ListItemText primary={item.title} />
            {isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List key={`sublist_${item.uid}`} component="div" disablePadding>
              {myList.map((subItem) => (
                getParserFromList(subItem, item.icon !== undefined)
              ))}
            </List>
          </Collapse>
        </List>
      );

    } else {
      // here is basic ListItemLinkProps
      getParserFromList(item, false);
    }

    return <></>;
  }



  const list = (
    <MenuBox
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
    >
      <List>
        {inputList.map((item) => (
          getListFilter(item)
        ))}
      </List>
    </MenuBox>
  );

  useEffect(() => {
    setOpen(isDrawerListOpen);
  }, [isDrawerListOpen]);

  useEffect(() => {
    if (!open) {
      setDrawerListState(false);
    }
  }, [open, setDrawerListState]);

  useEffect(() => {
    const iniNodeState: NodeState[] = [];
    inputList.forEach((item) => {
      if (isItemMatchCollapse(item)) {
        iniNodeState.push({ uid: item.uid, open: item.defaultOpenState !== undefined ? item.defaultOpenState : false })
      }
    });
    setNodeState(iniNodeState);
  }, [inputList]);

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={toggleDrawer(false)}
    >
      {list}
    </Drawer>
  );
};

export default DrawerList;
