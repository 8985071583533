import styled from 'styled-components';

export const OAuthLogo = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 30px;
`;

export const BtnText = styled.p`
  color: #29293d;
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-left: 11px;
  margin-right: 30px;
  font-family: Roboto;
  font-weight: bold;
`;

export const BtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  border: 1px solid rgb(82, 82, 122, 0.7);
  transition: background-color 0.5s ease;
  cursor: pointer;
  &:hover {
    background-color: rgb(194, 194, 214);
  }
`;
