
import { createTheme } from '@mui/material/styles';
import { green, orange } from '@mui/material/colors';

export const myTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: 'rgb(103, 180, 248)',
      contrastText: '#fff',
    },
    success: {
      main: green[300],
    },
    text: {
      primary: '#fff',
    },
    background: {
      default: '#21130d', //设置默认背景颜色
      paper: '#6e7ff3', //设置纸张或容器的背景颜色
    },
  },
  typography: {
    // Tell Material UI what the font-size on the html element is.
    htmlFontSize: 14,
    fontFamily: 'Arial, sans-serif',
  },
});

export default myTheme;