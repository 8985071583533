import { Card, Paper, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const CardBox = muiStyled(Card)`
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
`;

export const PaperBox = muiStyled(Paper)`
  padding: 20px;
  height: 100vh;
  display: grid;
  grid-row-gap: 1px;
  grid-column-gap: 2px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* 在小屏幕下容器只有一列 */
  }
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
`;

export const ImgBox = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10%;
  box-shadow: 10px 10px 14px rgba(0, 0, 0, 0.5);
`;

export const H1 = muiStyled(Typography)`
  filter: drop-shadow(12px 12px 10px rgba(0, 0, 0, 0.4));
`;

// &:hover {
//   filter: drop-shadow(16px 16px 10px rgba(0, 0, 0, 0.4));
//   cursor: pointer;
// }
