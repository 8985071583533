import { FC } from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { TimeLineItem } from "./types";

interface CustomizedTimelineProps {
  content: TimeLineItem[]
}

export const CustomizedTimeline: FC<CustomizedTimelineProps> = (prop) => {

  return (
    <Timeline position="alternate">
      {prop.content.map((_item, index) => {
        return (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              align="left"
              variant="h5"
              color="text.secondary"
            >
              {_item.leftItem}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot sx={{ bgcolor: "#ffffff" }}>
                {_item.dot}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent align="left" sx={{ py: '50px', px: 2 }}>
              <Typography variant="body2">
                {_item.rightItem}
              </Typography >
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline >
  );
}

export default CustomizedTimeline;