import { FC } from "react";
import { H1, ImgBox, PaperBox } from "./style";
import { Box, Typography } from "@mui/material";
import { SlickPage } from "../../components/slickPage";
import { Button1 } from "../../styles";
import { useNavigate } from "react-router-dom";

interface HomeProps { }

export const HomePage: FC<HomeProps> = (props) => {
  const navigate = useNavigate();

  const slickLogoImg = [
    <ImgBox src="/logo_fluxnote.PNG" />,
  ]

  const slickImg = [
    <ImgBox src="/business_demo.png" />,
  ]

  const handleClick = () => {
    navigate('/login');
  }

  return (
    <PaperBox id="login-page">
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 5 }}>
        <H1 variant="h1" noWrap>Flux Note</H1>
        <Typography variant="body1" sx={{ marginTop: 5 }}>Designed for creating templates for wiki, blog, and docs.</Typography>
        <Button1 variant="contained" sx={{ marginTop: 5 }} onClick={handleClick}>Try it free</Button1>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SlickPage ReactElement={slickLogoImg} />
      </Box>
      <SlickPage ReactElement={slickImg} />
      <Box sx={{ marginLeft: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
        <Typography variant="h4">A powerful collaboration tool</Typography>
        <Typography variant="subtitle1">Unified work platform:</Typography>
        <Typography variant="body1"> allows team members to collaborate on the same platform, reducing communication difficulties and time wasted due to using different tools.</Typography>
        <Typography variant="subtitle1">Organization and management:</Typography>
        <Typography variant="body1"> offers many features such as categorization, tagging, and searching to help you easily organize and manage your content.</Typography>
      </Box>
    </PaperBox>
  );
};

export default HomePage;