import { FC } from "react";
import { useRouteError } from "react-router-dom";
import { Container } from "../../styles";

interface ErrorPageProps { }

export const ErrorPage: FC<ErrorPageProps> = (props: ErrorPageProps) => {
  const error: any = useRouteError();
  console.error(error);

  return (
    <Container id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.text || error.message}</i>
      </p>
    </Container>
  );
};

export default ErrorPage;