import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  isAuthenticated: boolean;
  authenticationPath: string;
  route: JSX.Element;
}

export const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const { isAuthenticated, authenticationPath, route } = props;
  if (isAuthenticated) {
    return route;
  }

  return (
    <Navigate to={{ pathname: authenticationPath }} />
  );
}

export default PrivateRoute;
