import { FC, useEffect } from "react";
import { PaperBox } from "./styles";
import { useLocation } from "react-router-dom";

interface AuthCallBackProps {

}

export const AuthCallBack: FC<AuthCallBackProps> = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accessToken = searchParams.get('access_token');

  useEffect(() => {
    console.log(accessToken);
  }, [accessToken])


  return <PaperBox>

  </PaperBox>
}

export default AuthCallBack;