import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuBox = styled(Box)`
  background-image: linear-gradient(
    -225deg,
    #3d4e81 0%,
    #5753c9 48%,
    #6e7ff3 100%
  );
  flex: 1;
`;
