import { Box, TextField } from "@mui/material";
import { FC, ReactNode, useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface BlockSelectorProps {
  isLock: boolean;
  children?: ReactNode;
  newNextBlock: () => void;
  callBackIndex: () => void;
  deleteBlock: () => void;
  titleBlock?: boolean;
}


const options = [
  'None',
  'Atria',
  'Callisto',
  'Dione',
  'Ganymede',
  'Hangouts Call',
  'Luna',
  'Oberon',
  'Phobos',
  'Pyxis',
  'Sedna',
  'Titania',
  'Triton',
  'Umbriel',
];

const ITEM_HEIGHT = 48;

export function LongMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export const BlockSelector: FC<BlockSelectorProps> = (props) => {
  const { children, newNextBlock, callBackIndex, deleteBlock, titleBlock } = props;
  const [isDragIndicatorEnable, setDragIndicatorEnable] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [placeHolderText, setPlaceHolderText] = useState('');

  const handleMouseOver = () => {
    setDragIndicatorEnable(true);
  };

  const handleMouseLeave = () => {
    setDragIndicatorEnable(false);
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Enter':
        if (event.shiftKey) {
          // 處理 'Shift' + 'Enter' 的情況
          event.preventDefault();
          console.log('shift + Enter key pressed');
          setInputValue((prevValue) => prevValue + '\n');
          break;
        } else {
          event.preventDefault();
          // 處理單獨 'Enter' 的情況
          console.log('Enter key pressed');
          // 添加你的其他邏輯...
          event.currentTarget.blur();
          newNextBlock();
          break;
        }
      case 'Backspace':
        if (inputValue === '') {
          deleteBlock();
        }
        break;

      case 'Escape':
        // 處理 Escape 鍵事件
        break;
      default:
      // 其他按鍵的處理邏輯
    }

  };

  useEffect(() => {

  }, [])

  return <Box className='block-base'
    sx={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'row', paddingLeft: '150px', paddingRight: '150px', bgcolor: '#688' }}
    onMouseOver={handleMouseOver}
    onMouseLeave={handleMouseLeave}>
    <Box className='Drag-Icon' sx={{ width: '40px', cursor: 'grab' }} >
      {titleBlock ? null : isDragIndicatorEnable ? <DragIndicatorIcon sx={{ height: '100%' }} /> : null}
    </Box>
    <TextField
      sx={{
        border: 'none',
        borderBottom: 'none',
        outline: 'none',
        width: '100%',
        height: 'auto',
        backgroundColor: '#222',
        overflow: 'hidden', // 隱藏滾動軸
        resize: 'none', // 禁用 textarea 的 resize
      }}
      InputProps={{
        sx: {
          fontSize: titleBlock ? '2rem' : '1rem',
        },
      }}
      value={inputValue}
      onChange={handleInputChange}
      placeholder={placeHolderText}
      onKeyDown={handleEnterKeyPress}
      multiline
      autoFocus
      onFocus={() => {
        callBackIndex();
        setPlaceHolderText("Press \'/\' for command");
      }}
      onBlur={() => { setPlaceHolderText("") }}
    />
    {children && (
      <div style={{ position: 'absolute', top: '0', left: '0' }}>
        {children}
      </div>
    )}
  </Box>
}

export default BlockSelector;