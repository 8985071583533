import { Box, Paper, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const PaperBox = muiStyled(Paper)`
  padding: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
`;

export const LoginCard = muiStyled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #c222cc;
`;

export const Title = muiStyled(Typography)`
  font-weight: bold;
  letter-spacing: 2px;
`;

export const ImgBox = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 10%;
  box-shadow: 10px 10px 14px rgba(0, 0, 0, 0.5);
`;