import { Box } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { MarkdownComponent } from "../../components/markdownComponent";
import { useEffect, useState } from "react";
import { isPostIdWhitelisted } from "../../constants/whiteList";

export default function Posts() {
  const postData = useLoaderData() as string;
  const [md, setMD] = useState('')

  async function finder(postId: string) {

    if (isPostIdWhitelisted(postId)) {
      try {
        const response = await fetch(`/docs/${postId}.md`);
        const markdown = await response.text();
        setMD(markdown);
      } catch (e) {
        setMD('');
        console.log(`error: ${e}`);
      }
    } else {
      // postId is not on white list 
      setMD('');
    }



  }

  useEffect(() => {
    finder(postData);
  }, [postData])

  return (
    <Box sx={{ padding: 10 }}>
      <MarkdownComponent markdown={md} />
    </Box>
  );
}
