import { FC } from "react";
import { BtnText, BtnWrap, OAuthLogo } from "./styles";

interface GoogleOAuthProps {
  onClick?: () => void;
}

export const GoogleOAuth: FC<GoogleOAuthProps> = (prop) => {
  return <BtnWrap onClick={prop.onClick}>
    <OAuthLogo src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
    <BtnText>Continue with Google</BtnText>
  </BtnWrap>
}

export default GoogleOAuth;