import { FC, useEffect, useState } from "react";
import { Card, CardProject, ImgBox, PaperBox, RowGroup, Separator, Table, TableCell, Title } from "./styles";
import { AppBar, Avatar, Box, IconButton, Stack, TableBody, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { CustomizedTimeline, TimeLineItem } from "../../components/customizedTimeline";
import AdjustIcon from '@mui/icons-material/Adjust';
import { Carousel } from "../../components/carousel";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

interface BioPageProps {
}

export const BioPage: FC<BioPageProps> = (props) => {
  const [isLightMode, setIsLightMode] = useState(true);

  const toggleColorMode = () => {
    setIsLightMode((prevMode) => (!prevMode));
  }

  const settings = {
    dots: true,
  };

  const handleLinkClickORing = () => {
    window.location.href = 'https://oringnet.com/';
  };

  const handleLinkClickLohas = () => {
    window.location.href = 'https://npo.ilohas.info/';
  };

  const MyDegree: TimeLineItem[] = [{
    leftItem: "2016 - 2017",
    dot: <Avatar alt="CYCU" src="https://www.cycu.edu.tw/eng/favicon.png" sx={{ width: 34, height: 34, bgcolor: "#ffffff" }} />,
    rightItem: "MASTER PROGRAM IN COMMUNICATION ENGINEERING",
  }, {
    leftItem: "2011 - 2015",
    dot: <Avatar alt="CYCU" src="https://www.cycu.edu.tw/eng/favicon.png" sx={{ width: 34, height: 34, bgcolor: "#ffffff" }} />,
    rightItem: "ELECTRICAL ENGINEERING",
  }];

  const MyWorkExp: TimeLineItem[] = [{
    leftItem: <Box sx={{ cursor: 'pointer' }} onClick={handleLinkClickORing}>ORing Industrial Networking</Box>,
    dot: <AdjustIcon sx={{ color: '#2929' }} />,
    rightItem: <>
      <Typography component={'span'} variant={'body2'}>
        I am currently serving as the Frontend Technology Supervisor at ORing Cloud Team.
        Most of the projects I have worked on involve IoT-related technologies as the application
        scenarios. Here are the communication technologies and software frameworks we use:
        Communication-related: MQTT, Low Energy Bluetooth (BLE), Modbus
        Frameworks and Technologies: React, Redux Toolkit, React hooks, React Query, RxJS, SWR
        Web UI Framework: MUI, Native Base
        Mobile UI: React Native Element, Native Base
        Cloud: Cloudflare, Google Cloud Platform (GCP), Firebase, Vercel</Typography>
    </>,
  }, {
    leftItem: <Box sx={{ cursor: 'pointer' }} onClick={handleLinkClickLohas}>ilohas</Box>,
    dot: <AdjustIcon sx={{ color: '#2929' }} />,
    rightItem: <>
      <Typography component={'span'} variant={'body1'}>
        By utilizing the advertising and marketing services of Chung Yuan Christian University, we collaborate with businesses to assist them in integrated marketing.
      </Typography>
      <Typography component={'span'} variant={'body1'}>
        We employ WordPress to build official websites, incorporating Web UI / UX design.
      </Typography>
    </>,
  }];

  const slides_pumpman = [
    <ImgBox src="/images/pumpman_app01.png" />
    ,
    <ImgBox src="/images/pumpman_app02.png" />
    ,
  ];

  const slides_commander = [
    <ImgBox src="/images/Commander_01.png" />
    ,
    <ImgBox src="/images/Commander_02.png" />
    ,
  ];

  const slides_fogLight = [
    <ImgBox src="/images/foglight_web_01.png" />
    ,
    <ImgBox src="/images/foglight_web_02.png" />
    ,
    <ImgBox src="/images/foglight_web_03.png" />
    ,
  ];

  useEffect(() => {
    document.title = 'Founder Resume';
  }, [])

  return <PaperBox sx={{ backgroundImage: isLightMode === true ? 'linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%)' : 'linear-gradient(to right, #434343 0%, black 100%)' }}>
    <AppBar position="static">
      <Toolbar sx={{ bgcolor: isLightMode === true ? '#ffffff' : ' #434343 ', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Box>
          <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
            {isLightMode ? <Brightness4Icon sx={{ color: 'black' }} /> : <Brightness7Icon sx={{ color: 'yellow' }} />}
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>


    <RowGroup>
      <Card sx={{ flex: 2, height: '350px', backgroundImage: isLightMode === true ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' : 'linear-gradient(to right, #121212 0%, black 80%)' }}>
        <Stack
          justifyContent="center"
          alignItems="flex-start"
          direction={{ xs: 'column', sm: 'column' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Avatar
            alt="Founder Jagger"
            src="/images/founder.jpeg"
            sx={{ width: 170, height: 170 }}
          />
          <Title variant="h5">👋 Hello, I,m Jagger Hsu.</Title>
          <Title variant="subtitle1">I am a cross-platform mobile app and web application developer. Currently, I work at ORing Cloud Team as a Lead Front-end Developer.</Title>

        </Stack>
      </Card>
      <Card sx={{ height: '350px', backgroundImage: isLightMode === true ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' : 'linear-gradient(to right, #121212 0%, black 80%)' }}>
        <Stack
          justifyContent="flex-start"
          alignItems="flex-start"
          direction={{ xs: 'column', sm: 'column' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Title variant="h5">Experience</Title>
          <Separator />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Platforms</TableCell>
                <TableCell>Lang</TableCell>
                <TableCell>Exp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Android native</TableCell>
                <TableCell>Java</TableCell>
                <TableCell>2017 ~ 2021</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Windows Desktop</TableCell>
                <TableCell>C++</TableCell>
                <TableCell>2017 ~ 2019</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>IOS native</TableCell>
                <TableCell>swift</TableCell>
                <TableCell>2019 ~ 2021</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>React Native</TableCell>
                <TableCell>Typescript / React</TableCell>
                <TableCell>2021 ~ now</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Card>
    </RowGroup>
    <RowGroup>
      <Card sx={{ height: '350px', backgroundImage: isLightMode === true ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' : 'linear-gradient(to right, #121212 0%, black 80%)' }}>
        <Stack
          justifyContent="flex-start"
          alignItems="space-between"
          direction={{ xs: 'column', sm: 'column' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Title variant="h5">Professional</Title>
          <Separator />
          <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box
              sx={{ display: "flex", flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}
            >
              <Title variant="body1" paragraph={true}>🔹 React Native</Title>
              <Title variant="body1" paragraph={true}>🔹 React Web</Title>
              <Title variant="body1" paragraph={true}>🔹 Android App</Title>
              <Title variant="body1" paragraph={true}>🔹 IOS App</Title>
              <Title variant="body1" paragraph={true}>🔹 Desktop App</Title>
            </Box>
            <Box
              sx={{ display: "flex", flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}
            >
              <Title variant="body1" paragraph={true}>🔹 BLE</Title>
              <Title variant="body1" paragraph={true}>🔹 NFC</Title>
              <Title variant="body1" paragraph={true}>🔹 IoT</Title>
            </Box>
          </Box>


        </Stack>
      </Card>
      <Card sx={{ height: '350px', backgroundImage: isLightMode === true ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' : 'linear-gradient(to right, #121212 0%, black 80%)' }}>
        <Stack
          justifyContent="flex-start"
          alignItems="space-between"
          direction={{ xs: 'column', sm: 'column' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Title variant="h5">Lang</Title>
          <Separator />
          <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box
              sx={{ display: "flex", flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}
            >
              <Title variant="body1" paragraph={true}>🔹 Typescript</Title>
              <Title variant="body1" paragraph={true}>🔹 Java</Title>
              <Title variant="body1" paragraph={true}>🔹 C++</Title>
              <Title variant="body1" paragraph={true}>🔹 Swift</Title>
              <Title variant="body1" paragraph={true}>🔹 Git</Title>
            </Box>
            <Box
              sx={{ display: "flex", flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}
            >
              <Title variant="body1" paragraph={true}>🔹 Javascript</Title>
              <Title variant="body1" paragraph={true}>🔹 css</Title>
              <Title variant="body1" paragraph={true}>🔹 html</Title>
            </Box>
          </Box>
        </Stack>
      </Card>
      <Card sx={{ height: '350px', backgroundImage: isLightMode === true ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' : 'linear-gradient(to right, #121212 0%, black 80%)' }}>
        <Stack
          justifyContent="flex-start"
          alignItems="space-between"
          direction={{ xs: 'column', sm: 'column' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Title variant="h5">Framework / Kit</Title>
          <Separator />
          <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box
              sx={{ display: "flex", flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}
            >
              <Title variant="body1" paragraph={true}>🔹 Redux</Title>
              <Title variant="body1" paragraph={true}>🔹 React Hook</Title>
              <Title variant="body1" paragraph={true}>🔹 ReactNative</Title>
              <Title variant="body1" paragraph={true}>🔹 RTK</Title>
              <Title variant="body1" paragraph={true}>🔹 SWR</Title>
            </Box>
            <Box
              sx={{ display: "flex", flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}
            >
              <Title variant="body1" paragraph={true}>🔹 MapBox</Title>
              <Title variant="body1" paragraph={true}>🔹 Firebase</Title>
            </Box>
          </Box>
        </Stack>
      </Card>
    </RowGroup>
    <RowGroup>
      <Card sx={{ backgroundImage: isLightMode === true ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' : 'linear-gradient(to right, #3c3a3a 100%, black 80%)' }}>
        <Stack
          justifyContent="flex-start"
          alignItems="space-between"
          direction={{ xs: 'column', sm: 'column' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Title variant="h5" align="center">Degree</Title>
          <Separator />
          <CustomizedTimeline content={MyDegree} />
        </Stack>
      </Card>
    </RowGroup>
    <RowGroup>
      <Card sx={{ backgroundImage: isLightMode === true ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' : 'linear-gradient(to right, #3c3a3a 100%, black 80%)' }}>
        <Stack
          justifyContent="flex-start"
          alignItems="space-between"
          direction={{ xs: 'column', sm: 'column' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Title variant="h5" align="center">Work Exp</Title>
          <Separator />
          <CustomizedTimeline content={MyWorkExp} />
        </Stack>
      </Card>
    </RowGroup>
    <RowGroup>
      <CardProject sx={{ justifyContent: 'space-around', backgroundImage: isLightMode === true ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' : 'linear-gradient(to right, #121212 0%, black 80%)' }}>
        <Carousel slides={slides_pumpman} />
        <Box sx={{ flexDirection: "column", justifyContent: 'flex-start', padding: '20px' }}>
          <Typography variant="h3">
            智慧量水 App
          </Typography>
          <Typography variant="body1" sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
            📱 App Platform: IOS + Android
          </Typography>
          <Typography variant="body1">
            PumpMan provides a complete remote operation system to help young farmers conveniently irrigate their crops.

            With just a few taps on the app, they can easily start or stop the water pumps near their farmland.

            Through the app, users can arrange different water wells and their corresponding components (such as water pumps, crops, GPS, etc.) according to their preferences.
          </Typography>
        </Box>
      </CardProject>
    </RowGroup>
    <RowGroup>
      <CardProject sx={{ justifyContent: 'space-around', backgroundImage: isLightMode === true ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' : 'linear-gradient(to right, #121212 0%, black 80%)' }}>
        <Carousel slides={slides_commander} />
        <Box sx={{ flexDirection: "column", justifyContent: 'flex-start', padding: '20px' }}>
          <Typography variant="h4">
            Commander App
          </Typography>
          <Typography variant="body1" sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
            📱 App Platform: IOS + Android
          </Typography>
          <Typography variant="body1">
            Commander is a dedicated IoT device management app for ORing. With Commander, you can use BLE to scan nearby IoT devices and quickly establish connections and configurations.

            Additionally, you can search for registered devices through the internet.

            By logging into your ORing corporate account, you can use the app to manage and configure the paired IoT devices.
          </Typography>
        </Box>
      </CardProject>
    </RowGroup>
    <RowGroup>
      <CardProject sx={{ justifyContent: 'space-around', backgroundImage: isLightMode === true ? 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)' : 'linear-gradient(to right, #121212 0%, black 80%)' }}>
        <Carousel slides={slides_fogLight} />
        <Box sx={{ flexDirection: "column", justifyContent: 'flex-start', padding: '20px' }}>
          <Typography variant="h4">
            Fog Light
          </Typography>
          <Typography variant="body1" sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
            🌐 Platform: Web (architecture design only)
          </Typography>
          <Typography variant="body1">
            Fog-Light is a platform dedicated to controlling highway fog lights. It allows monitoring of the controller's battery level, solar panel charging status, and fog light brightness.

            Additionally, the platform can integrate with the meteorological bureau's API to provide information on the activation of fog lights during heavy fog.
          </Typography>
        </Box>
      </CardProject>
    </RowGroup>
  </PaperBox>
}

export default BioPage;