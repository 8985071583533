import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { PostBox } from '../../styles';

interface MarkdownComponentProps {
  markdown: string;
}

const img_hooks = (path: string) => {
  return <img src={path} />
}

export const MarkdownComponent: FC<MarkdownComponentProps> = ({ markdown }) => {
  return <PostBox>
    <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} components={{
      em: ({ node, ...props }) => <i style={{ color: 'red' }} {...props} />,
      img: ({ node, ...props }) => img_hooks(props.src?.toString()!)
    }} />
  </PostBox>

};

export default MarkdownComponent;
