import { Box, Button, InputBase, Toolbar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const SideContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  background-color: #f7e467e6;
`;

export const ToolbarBox = styled(Toolbar)`
  background-image: linear-gradient(
    -225deg,
    #3d4e81 0%,
    #5753c9 48%,
    #6e7ff3 100%
  );
`;

export const RootBox = styled(Box)`
  flex-grow: 1;
  background-image: linear-gradient(to top, #37ecba 0%, #72afd3 100%);
`;

export const PostBox = styled(Box)`
  padding: 25px;
  border-radius: 15px;
  background-color: #cddcdc;
  background-image: linear-gradient(
    to top,
    #bdc2e8 0%,
    #bdc2e8 1%,
    #e6dee9 100%
  );
`;

export const Button1 = styled(Button)``;

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
