import { Box } from '@mui/material';
import styled from 'styled-components';

export const PageBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
`;
