import { FC, useEffect, useMemo, useState } from "react";
import { Container } from "./styles";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

interface CarouselProps {
  slides: React.ReactNode[];
}

export const Carousel: FC<CarouselProps> = (prop) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const MAX = prop.slides.length - 1;

  const handleClickNext = () => {
    if (currentIndex < MAX) {
      setCurrentIndex(preValue => preValue + 1);
    }
  }

  const handleClickBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(preValue => preValue - 1);
    }
  }

  const renderContent = useMemo(() => {
    return prop.slides[currentIndex]
  }, [currentIndex]);

  return (
    <Container>
      <div onClick={handleClickBack}>
        <ArrowCircleLeftIcon sx={{ fontSize: 20 }} />
      </div>
      {renderContent}
      <div onClick={handleClickNext}>
        <ArrowCircleRightIcon sx={{ fontSize: 20 }} />
      </div>
    </Container>
  );
}

export default Carousel;