import { FC } from "react";
import { PaperBox } from "./styles";
import { Outlet } from "react-router-dom";

interface UserProps {

}

export const User: FC<UserProps> = (props) => {


  return <PaperBox>
    <div id="detail">
      <Outlet />
    </div>
    <p>{process.env.REACT_APP_NODE_ENV}</p>
  </PaperBox>
}

export default User;