import { Box, Paper, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const PaperBox = muiStyled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  padding-bottom: 20px;
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
`;

export const LoginCard = muiStyled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const RowGroup = muiStyled(Box)`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
gap: 20px;
margin-top: 80px;
padding-left: 20px;
padding-right: 20px;
@media (max-width: 768px) {
  flex-direction: column;
}
`;

export const Separator = styled.div`
  height: 4px;
  width: 100%;
  background-color: #c222cc;
`;

export const Title = muiStyled(Typography)`
  font-weight: bold;
  letter-spacing: 2px;
`;

export const Card = muiStyled(Box)`
display: flex;
flex: 1;
width: 100%;
height: 100%;
flex-direction: column;
justify-content: start-content;
align-items: stretch;
background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
padding: 1em;
border-radius: 1em;
`;

export const CardProject = muiStyled(Box)`
display: flex;
flex: 1;
width: 100%;
height: 100%;
flex-direction: row;
justify-content: start-content;
align-items: stretch;
background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
padding: 1em;
border-radius: 1em;
@media (max-width: 768px) {
  flex-direction: column;
}
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TableCell = styled.td`
  border: 2px solid #ffffff;
  padding: 8px;
`;

export const ImgBox = styled.img`
  width: 700px;
  height: 400px;
  object-fit: contain;
  @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
  }
`;