import { FC } from "react";
import { ImgBox, LoginCard, PaperBox, Separator, Title } from "./styles";
import { GithubOAuth, GoogleOAuth } from "../../components/oauth";

interface LoginProps {

}

export const Login: FC<LoginProps> = (props) => {

  const handleClickGoogleOAuth = () => {
    const googleOAuthURL =
      'https://accounts.google.com/o/oauth2/v2/auth?' +
      new URLSearchParams({
        client_id:
          '138321105345-th4us33ufc4mg647sti457719igsa6ke.apps.googleusercontent.com',
        redirect_uri: 'https://api.imaker-studio.com/auth/google/callback', // 您的 OAuth 回調 URL
        response_type: 'code',
        scope: 'profile email',
        access_type: 'offline',
        prompt: 'select_account',
      }).toString();

    // 嘗試開啟 Google OAuth 彈出視窗
    const popup = window.open(
      googleOAuthURL,
      'google-oauth',
      'width=500,height=600'
    );

    if (!popup) {
      alert('彈出窗口被阻止，請允許瀏覽器彈出窗口');
      return;
    }

    // 監控小視窗的關閉事件
    const checkPopupClosed = setInterval(() => {
      if (popup.closed) {
        clearInterval(checkPopupClosed);
        console.log('Google OAuth 認證完成或被取消');
        // 在此處執行其他動作，例如重新整理頁面或檢查授權狀態
      }
    }, 1000);
  };

  return (
    <PaperBox>
      <LoginCard>
        <ImgBox src='/logo_fluxnote.PNG' />
        <Title variant='h2'>Log in</Title>
        <Separator />
        <GoogleOAuth onClick={handleClickGoogleOAuth} />
        <GithubOAuth />
      </LoginCard>
    </PaperBox>
  );
}

export default Login;