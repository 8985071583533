import styled from 'styled-components';

export const Slide = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: #f210f0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
`;
